@import url('components/Button.css');

h2 {
  text-shadow: 3px 3px 0 white;
  font-size: 6cqw;
  margin: 20px;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* background-color: #ef8833; */
  /* background-image: linear-gradient(to right, #fff0e3, #fff1eb, #fff4f4, #fff7fa, #fffbfe, #fefafe, #fdf9fd, #fcf8fd, #fcf2f9, #fdecf3, #fee6eb, #ffe0e1); */
  background-color: #00ce67;
}

.startScreen {
  text-align: center;  
  background-size: 45%;
  background-repeat: no-repeat;    
  background-position: left bottom;
  height: 100vh;
}

.start {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  align-items: center;
}

.start img.logo {
  max-width: 35vw;
  margin: auto;
  width: 100%;
}

.teamNameInputContainer {
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 50%;
  margin: auto;    
  font-size: 20px;
}

.teamNameInputContainer input,
.teamNameInputContainer select,
select {
  padding: 5px;
  font-size: 20px;
  border: 3px solid teal;
  border-radius: 4px;
}

.actionButtons {
  background-color: #d0ffae;
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px 20px 0 0;
}

.hud {
  display: flex;
  font-size: 3rem;
  align-items: center;
  padding: 0 10px;
  font-weight: bold;
  background-color: #d0ffae;
}

.hud div {
  flex-grow: 1
}

.hudGameMode {
  top: 10%;
  position: absolute;
  background-color: #f6dcdc;
  padding: 10px;
  border-radius: 0 0 3px 3px;
  font-size: 15px;
}

.question {
  flex: 1;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;  
  height: 50vh;
}

.questionCountContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.questionCount {
  background-color: lightgrey;
  height: 9px;
}

.questionCount.correct {
  background-color: green;
}

.questionCount.wrong {
  background-color: red;
}

.hudBlock {
  font-family: monospace;
}

.hudBlock.team {
  padding: 5px;
}

.hudBlock.team.active {
  animation: fade-to-transparent 1s infinite ease-in-out;
}

.hudBlock span {
  display: block;
  /* font-size: 20px; */
}

.bg1 {
  background-image: linear-gradient(to bottom, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
}

.bg2 {
  background-image: linear-gradient(to left top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
}

.bg3 {
  background-image: linear-gradient(to left top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
}

.question h1 {
  font-size: 12em;
  color: white;
  text-shadow: 2px 2px 0px black;
  text-align: center;
}

.answers {
  display: flex;
  height: 25vh;
  padding: 5px;
  gap: 5px;
}

.answers .answer {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: cornflowerblue;
  font-weight: bold;
  font-size: 9em;
  color: white;
  text-shadow: 2px 2px 0px black;
}

.answers .answer.correct {
  background-color: darkgreen !important;
  border: 15px solid green;
}

.answers .answer.wrong {
  background-color: darkred !important;
  border: 15px solid red;
}

.controls {
  position: absolute;
  top: 20%;
  left: 15px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  background-color: white;
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #2196f3;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

button.category {
  background-color: #f44336;
}

button:hover::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.5s ease-in-out;
}

button:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: all 0.5s ease-in-out;
}

button:hover {
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

button:focus {
  outline: none;
}

.categoryList {
  overflow-x: scroll;
}

.categories-selected, .categories-available {
  display: inline-flex;
  gap: 5px;
  padding: 10px;
  background-color: #f6dcdc;
  border-top: 1px solid #656060;
  min-height: 40px;
}

@keyframes fade-to-transparent {
  0% {
    background-color: rgb(60, 193, 109, 1); /* Starting color with full opacity */
  }
  50% {
    background-color: rgba(255, 255, 255, 0); /* Mid-point color with full transparency */
  }
  100% {
    background-color: rgba(60, 193, 109, 1); /* Ending color with full opacity */
  }
}

.score {
  font-size: 19cqw;
  line-height: 10cqw;
  text-shadow: 3px 3px 0 white;
  font-weight: bold;
}

.score span {
  font-size: 4cqw;
  display: block;
}

.cardList {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 20px;
  overflow-x: scroll;
}

.card {
  background-color: lightgrey;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card img {
  width: 200px;
  height: 200px;
}

.cardButtons {
  margin-top: auto;
}

.versionInfo {
  position: fixed;
  left: 10px;
  bottom: 10px;
  font-style: italic;
}

.infoPopup {
  background-color: #3dbcf8;
  padding: 20px;
  font-weight: bold;
  border-radius: 40px;
  position: absolute;
  top: 10px;
  right: 20px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}

.listItemCount {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: antiquewhite;
  border-radius: 20px 0 0 0;
}